<ng-container>
  <div class="status-wrapper">
    <div class="loading-item">
      <div class="content">
        <div class="controls">
          <button class="close-btn" (click)="cancel()">
            <app-icons name="close_1" [width]="24" [height]="24" fillColor="#FFFFFF"></app-icons>
          </button>
        </div>
        <img class="" [src]="photo" alt="">
        <span class="percent text-color-light">{{percent}}%</span>
        <div class="progress" [ngStyle]="{'width.%': 100 - percent}"></div>
      </div>

    </div>
  </div>
</ng-container>