import { AfterContentInit, afterNextRender, Component, ElementRef, HostListener, inject, NgZone, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { IconsComponent } from './uikit/icons/icons.component';
import { StorageDataKey, StorageService } from './service/storage.service';
import { ApiService } from './api/api.service';
import { CommonModule, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { SocialAuthComponent } from './components/social-auth/social-auth.component';
import { UserRepositoryService } from './repository/user-repository.service';
import { UnauthorizedError, UserNotFoundError } from './api/interceptors/error.interceptor';
import { MessagesApiService } from './api/ws.service';
import { PaymentsService } from './service/payments.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import * as uuid from 'uuid';
import { BehaviorSubject, filter, groupBy, mergeMap, Subscription, tap } from 'rxjs';
import { RoutingService } from './service/routing.service';
import { NotificationsRepositoryService } from './repository/notifications-repository.service';
import { ToastrService } from 'ngx-toastr';
import cloneDeep from 'lodash-es/cloneDeep';
import { NotificationToastComponent } from './modules/notifications/components/notification-toast/notification-toast.component';
import { UserModelData } from 'desiren-core-lib/lib/types/users';
import { StyleInjectorService } from './service/style-inject.service';
import { defaultMetadata, MetadataService } from './service/meta.service';
import { environment } from '../environments/environment';
import { MediaPreviewDialogComponent } from './components/media-preview-dialog/media-preview-dialog.component';
import { SessionService } from './service/session.service';
import Smartlook from 'smartlook-client';
import { CookieDialogComponent } from './components/cookie-dialog/cookie-dialog.component';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ENotificationAction } from 'desiren-core-lib/lib/enums/notifications/notification-action.enum';
import { IMessagesTextMessageResponse } from 'desiren-core-lib/lib/types/messages/message/text.message.interface';
import { IMessagesMultipartMessageResponse } from 'desiren-core-lib/lib/types/messages/message/multipart.message.interface';
import { IMessagesPhotoMessageResponse } from 'desiren-core-lib/lib/types/messages/message/photo.message.interface';
import { IMessagesDocumentMessageResponse } from 'desiren-core-lib/lib/types/messages/message/document.message.interface';
import { IMessagesVoiceMessageResponse } from 'desiren-core-lib/lib/types/messages/message/voice.message.interface';
import { IMessagesAudioMessageResponse } from 'desiren-core-lib/lib/types/messages/message/audio.message.interface';
import { IMessagesMessagesVideoMessageResponse } from 'desiren-core-lib/lib/types/messages/message/video.message.interface';
import { IMessageResponse } from 'desiren-core-lib/lib/types/messages/message/_.message.interface';
import { PlatformService } from './service/platform.service';
import { EMessagesMessageNotificationChat } from 'desiren-core-lib/lib/enums/messages/message-notification.chat.messages.enum';
import { DialogService } from './service/dialog.service';
import { LooksLoadingStatusComponent } from './components/looks-loading-status/looks-loading-status.component';
import { LooksService } from './service/looks.service';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [CommonModule, RouterModule, IconsComponent, SocialAuthComponent, MediaPreviewDialogComponent, CookieDialogComponent, DialogModule, NgOptimizedImage],
	providers: [SsrCookieService, StorageService, ApiService, UserRepositoryService, MessagesApiService, SessionService],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
	userSub$?: Subscription;
	showAuth = false;
	@ViewChild('loader') loader!: ElementRef<HTMLDialogElement>;

	private readonly cookie: SsrCookieService = inject(SsrCookieService);
	private readonly storage: StorageService = inject(StorageService);
	private readonly payments: PaymentsService = inject(PaymentsService);
	private readonly activeRoute: ActivatedRoute = inject(ActivatedRoute);
	private readonly userRepo: UserRepositoryService = inject(UserRepositoryService);
	private readonly socket: MessagesApiService = inject(MessagesApiService);
	private readonly router: Router = inject(Router);
	private readonly zone: NgZone = inject(NgZone);
	private readonly afMessaging: AngularFireMessaging = inject(AngularFireMessaging);
	private readonly urlService: RoutingService = inject(RoutingService);
	private readonly api: ApiService = inject(ApiService);
	private readonly notifications: NotificationsRepositoryService = inject(NotificationsRepositoryService);
	private readonly toastr: ToastrService = inject(ToastrService);
	private readonly styleInjector: StyleInjectorService = inject(StyleInjectorService);
	private readonly platformId = inject(PLATFORM_ID);
	private readonly metadataService: MetadataService = inject(MetadataService);
	private readonly session: SessionService = inject(SessionService);
	private readonly dialog: Dialog = inject(Dialog);
	private readonly sso: ScrollStrategyOptions = inject(ScrollStrategyOptions);
	private readonly platform: PlatformService = inject(PlatformService);
	private readonly dialogService: DialogService = inject(DialogService);
	private readonly looksService: LooksService = inject(LooksService);
	// private isProgressBarActive = new Map<string, boolean>();

	public isProgressBarActive: boolean = false;
	public readonly routeSub$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
		this.urlService.prevUrl = this.urlService.currentUrl;
		this.urlService.setCurrentUrl(event.url);
		this.urlService.setPreviousUrl(this.urlService.prevUrl);
	});

	@HostListener('window:load', ['$event'])
	onLoad() {
		if (this.storage.getLocalJsonData(StorageDataKey.apiUser) == null) {
			this.userRepo.meSubject.next(null);
		}
	}

	private wsNotifications$: Subscription;
	private wsLooksLoadingStatus$: Subscription;
	private wsMessageNotifications$: Subscription;
	public isBrowser: boolean = false;

	constructor() {
		this.isBrowser = isPlatformBrowser(this.platformId);
		afterNextRender(() => {
			this.checkCookies();
			this.activeRoute.queryParamMap
				.pipe(
					tap((params) => {
						params.has('refId');
					})
				)
				.subscribe((data) => {
					if (data.has('refId') && !this.storage.getLocalData(StorageDataKey.referrerCode)) {
						this.storage.setLocalData(StorageDataKey.referrerCode, this.activeRoute.snapshot.queryParams['refId']);
					}
				});
			if (environment.isProd && typeof window != undefined) {
				window.console.log = () => {};
				window.console.dir = () => {};
				window.console.error = () => {};
			}
		});
	}

	ngOnInit() {
		this.requestPermission();
		this.wsNotifications$ = this.socket.notifications.subscribe((data) => {
			const opt = cloneDeep(this.toastr.toastrConfig);
			opt.toastComponent = NotificationToastComponent;
			opt.toastClass = 'notification-toast';
			opt.payload = data;
			opt.extendedTimeOut = 6 * 60 * 60;
			this.toastr.show('', '', opt);
			this.notifications.unread();
		});
		this.wsMessageNotifications$ = this.socket.messageNotifications
			.pipe(
				filter((key) =>
					[
						EMessagesMessageNotificationChat.NEW_MULTIPART_MESSAGE,
						EMessagesMessageNotificationChat.NEW_TEXT_MESSAGE,
						EMessagesMessageNotificationChat.NEW_PHOTO_MESSAGE,
						EMessagesMessageNotificationChat.NEW_DOCUMENT_MESSAGE,
						EMessagesMessageNotificationChat.NEW_VOICE_MESSAGE,
						EMessagesMessageNotificationChat.NEW_AUDIO_MESSAGE,
						EMessagesMessageNotificationChat.NEW_VIDEO_MESSAGE,
					].includes(key.type)
				),
				filter((key) => this.urlService.currentUrl !== `/messages/chat/${(key.data as IMessageResponse).chatId}`)
			)
			.subscribe((data) => {
				const opt = cloneDeep(this.toastr.toastrConfig);
				opt.toastComponent = NotificationToastComponent;
				opt.toastClass = 'notification-toast';
				if (data?.type === EMessagesMessageNotificationChat.NEW_MULTIPART_MESSAGE) {
					const message = data.data as IMessagesMultipartMessageResponse;
					opt.payload = data.data;
					opt.payload = {
						action: ENotificationAction.NEW_MULTIPART_MESSAGE,
						message: message,
					};
				}
				if (data?.type === EMessagesMessageNotificationChat.NEW_TEXT_MESSAGE) {
					const message = data.data as IMessagesTextMessageResponse;
					opt.payload = data.data;
					opt.payload = {
						action: ENotificationAction.NEW_TEXT_MESSAGE,
						message: message,
					};
				}
				if (data?.type === EMessagesMessageNotificationChat.NEW_PHOTO_MESSAGE) {
					const message = data.data as IMessagesPhotoMessageResponse;
					opt.payload = data.data;
					opt.payload = {
						action: ENotificationAction.NEW_PHOTO_MESSAGE,
						message: message,
					};
				}
				if (data?.type === EMessagesMessageNotificationChat.NEW_DOCUMENT_MESSAGE) {
					const message = data.data as IMessagesDocumentMessageResponse;
					opt.payload = data.data;
					opt.payload = {
						action: ENotificationAction.NEW_DOCUMENT_MESSAGE,
						message: message,
					};
				}
				if (data?.type === EMessagesMessageNotificationChat.NEW_VOICE_MESSAGE) {
					const message = data.data as IMessagesVoiceMessageResponse;
					opt.payload = data.data;
					opt.payload = {
						action: ENotificationAction.NEW_VOICE_MESSAGE,
						message: message,
					};
				}
				if (data?.type === EMessagesMessageNotificationChat.NEW_AUDIO_MESSAGE) {
					const message = data.data as IMessagesAudioMessageResponse;
					opt.payload = data.data;
					opt.payload = {
						action: ENotificationAction.NEW_AUDIO_MESSAGE,
						message: message,
					};
				}
				if (data?.type === EMessagesMessageNotificationChat.NEW_VIDEO_MESSAGE) {
					const message = data.data as IMessagesMessagesVideoMessageResponse;
					opt.payload = data.data;
					opt.payload = {
						action: ENotificationAction.NEW_VIDEO_MESSAGE,
						message: message,
					};
				}
				opt.extendedTimeOut = 6 * 60 * 60;
				this.toastr.show('', '', opt);
			});
		if (isPlatformBrowser(this.platformId)) {
			this.injectStyleAfterLoad();
		}
		this.metadataService?.updateMetadata(defaultMetadata);
		this.session.onLoggedInSub.subscribe((data) => {
			if (data.isLoggedIn) {
				this.updateUser(data.returnUrl);
			}
		});
		if (environment.isProd && typeof window !== 'undefined') {
			Smartlook.init('1fe4feeca011116a1c7fa27e495a195a3b00bdfa');
			Smartlook.record({});
		}

		this.wsLooksLoadingStatus$ = this.socket.looksStatusSubj$
			// .pipe(
			// 	groupBy((data) => data.id),
			// 	mergeMap((group$) =>
			// 		group$.pipe(
			// 			tap((data) => {
			// 				// console.log('group$', data);

			// 				const isActive = this.isProgressBarActive.get(data.id) || false;
			// 				console.log('isActive', isActive);
			// 				this.isProgressBarActive.set(data.id, true);
			// 				if (!isActive && data.status === 'CONVERTING') {
			// 					console.log('OPEN', data.id);
			// 					this.showProgressLoadingBar(data);
			// 					this.isProgressBarActive.set(data.id, true);
			// 				}

			// 				if (isActive && data.status === 'OPTIMIZING') {
			// 					console.log('CLOSE', data.id);
			// 					this.hideProgressLoadingBar(data.id);
			// 					// this.isProgressBarActive.set(data.id, false);
			// 				}
			// 			})
			// 		)
			// 	)
			// )
			// .subscribe();

			.subscribe({
				next: (data) => {
					this.looksService.looksLoadingStatus.next(data);
					console.log('looksStatusSubj', !this.isProgressBarActive, data.status === 'CONVERTING');
					if (!this.isProgressBarActive && data.status === 'CONVERTING') {
						console.log('OPEN!!!!!!');

						this.showProgressLoadingBar();
						this.isProgressBarActive = !this.isProgressBarActive;
					}
					if (this.isProgressBarActive && data.status === 'OPTIMIZING') {
						console.log('CLOSE');

						this.hideProgressLoadingBar();
					}
				},
			});
	}

	ngAfterContentInit() {
		this.checkUser().then(() => this.closeLoader());
	}

	ngOnDestroy(): void {
		this.routeSub$.unsubscribe();
		this.wsNotifications$.unsubscribe();
		this.wsLooksLoadingStatus$.unsubscribe();
		this.socket.disconnect();
	}

	requestPermission() {
		if (this.isBrowser) {
			setTimeout(() => {
				this.afMessaging.requestPermission.subscribe({
					next: () => {
						this.afMessaging.getToken.subscribe((token) => {
							this.storage.setLocalData(StorageDataKey.fcmToken, token);
							this.afMessaging.messages.subscribe((messaging) => {
								console.dir(messaging);
							});
						});
					},
					error: () => {},
				});
			}, 2000);
		}
	}

	checkCookies() {
		const allowCookie = this.cookie.check('allowCookie');
		if (!allowCookie) {
			setTimeout(() => {
				this.dialog.open(CookieDialogComponent, {
					hasBackdrop: false,
					maxWidth: '600px',
					height: '100%',
					panelClass: 'cookie-dialog-container',
					scrollStrategy: this.sso.noop(),
				});
			}, 5000);
		}
		if (!this.cookie.get('browserId').length) {
			this.cookie.set('browserId', uuid.v4(), {
				expires: new Date().valueOf() / 1000 + 60 * 60 * 24 * 365,
			});
		}
		this.storage.setLocalData(StorageDataKey.browserId, this.cookie.get('browserId'));
	}

	private injectStyleAfterLoad() {
		typeof window !== 'undefined'
			? window.addEventListener('load', () => {
					const styleContent = `
          #hubspot-messages-iframe-container {
            bottom: 78px !important;
            display: none !important;
          }
          @media screen and (min-width: 1024px) {
            #hubspot-messages-iframe-container {
              bottom: 0 !important;
              display: initial !important;
            }
          }
        `;

					setTimeout(() => {
						this.styleInjector.injectStyle(styleContent);
					}, 2000);
			  })
			: undefined;
	}

	async checkUser() {
		this.userSub$?.unsubscribe();
		const data = this.storage.getLocalData(StorageDataKey.jwtToken);
		if (data != undefined && data?.length > 0) {
			try {
				this.updateUser();
			} catch (e) {
				this.storage.clearLocalData(StorageDataKey.jwtToken);
				this.storage.clearLocalData(StorageDataKey.apiUser);
			}
		}
		if (!data) {
			this.userSub$ = this.userRepo.meSubject$.subscribe((data) => {
				this.showAuth = data == null;
			});
		}
	}

	closeLoader() {
		if (this.isBrowser) {
			setTimeout(() => {
				this.loader.nativeElement.close();
				this.loader.nativeElement.classList.remove('opened');
			}, 50);
		}
	}

	updateUser(returnUrl?: string): void {
		this.userSub$?.unsubscribe();
		this.userRepo
			.me()
			.then(
				(user) => {
					const userData = this.storage.getLocalJsonData(StorageDataKey.apiUser) as UserModelData | null;
					if (userData != null && userData?.isCreator != user.isCreator) {
						this.api
							.refreshToken({
								userId: this.userRepo.meLazy().cache.id,
								jwtToken: this.storage.getLocalData(StorageDataKey.jwtToken),
								fcmToken: this.storage.getLocalData(StorageDataKey.fcmToken),
								browserId: this.storage.getLocalData(StorageDataKey.browserId),
							})
							.then((data) => {
								this.storage.setLocalJsonData(StorageDataKey.jwtToken, data.jwtToken);
							});
					}
					this.storage.setLocalJsonData(StorageDataKey.apiUser, user.toJson());
					if (user.onboardingData != null) {
						this.storage.setLocalJsonData(StorageDataKey.onboarding, user.onboardingData);
					}
					if (this.router.url.includes('signup') || this.router.url.includes('signin')) {
						this.zone.run(() => {
							this.router.navigate([returnUrl ?? '/home']);
						});
					}
					this.notifications.unread();
					this.payments.getProfileCards().future().finally();
					this.socket.onConnect();
				},
				(e: any) => {
					if (e instanceof UnauthorizedError || e instanceof UserNotFoundError) {
						this.socket.disconnect();
						if (!(this.router.url.includes('signup') || this.router.url.includes('signin'))) {
							this.zone.run(() => {
								this.storage.clearLocalStorage();
								return this.router.navigate(['/signup'], {
									queryParams: {
										returnUrl: this.router.url,
									},
								});
							});
						}
					}
				}
			)
			.finally(() => {
				this.userSub$ = this.userRepo.meSubject$.subscribe((data) => {
					this.showAuth = data == null;
				});
			});
	}

	private showProgressLoadingBar() {
		console.log('showProgressLoadingBar');
		this.dialogService.openAtBody(LooksLoadingStatusComponent).subscribe((data) => console.log(data));
	}

	private hideProgressLoadingBar() {
		this.isProgressBarActive = !this.isProgressBarActive;
	}

	// private showProgressLoadingBar(data: any) {
	// 	console.log(`Show Progress Bar for ID: ${data.id}`);
	// 	const dialogRef = this.dialogService.openAtBody(LooksLoadingStatusComponent, { data });
	// 	dialogRef.subscribe((result) => {
	// 		console.log(`Progress Bar closed for ID: ${data.id}`, result);
	// 	});

	// 	this.looksService.looksLoadingStatus.next(data);
	// }

	// private hideProgressLoadingBar(id: string) {
	// 	console.log(`Hide Progress Bar for ID: ${id}`);
	// 	this.isProgressBarActive.set(id, false);
	// }
}
