import { CommonModule } from '@angular/common';
import { Component, inject, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, Subscription } from 'rxjs';
import { LooksService } from 'src/app/service/looks.service';
import { IconsComponent } from 'src/app/uikit/icons/icons.component';

@Component({
	selector: 'app-looks-loading-status',
	standalone: true,
	imports: [CommonModule, IconsComponent],
	templateUrl: './looks-loading-status.component.html',
	styleUrl: './looks-loading-status.component.scss',
})
export class LooksLoadingStatusComponent implements OnInit, OnDestroy {
	private readonly looksService: LooksService = inject(LooksService);
	@Input() data!: any;
	public source: Subscription;
	public percent = 0;
	public photo: string;
	// constructor(@Inject('DIALOG_DATA') public data: any) {}

	ngOnInit() {
		this.source = this.looksService.looksLoadingStatus$.subscribe({
			next: (update) => {
				console.log(`Update for ID: ${update.id}`, update);

				if (update) {
					this.percent = update.percent;
					console.log(update);
				}
				if (!this.photo) this.photo = update.file.file.url;
			},
		});
	}
	ngOnDestroy(): void {
		console.log('this.source this.source');

		this.source.unsubscribe();
	}
	close!: (result?: any) => void;
	cancel() {
		this.close(false);
	}
}
